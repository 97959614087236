import { useEffect, useState } from "react";
import { deleteAxiosWithToken, postAxiosWithToken } from "../../service/axios_service";
import { templatesEndPoint, templatesListEndPoint } from "../../service/api_endpoints";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import Loading from "../../session/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../redux/layout_slice";
import { Description, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { setTemplatesDataList, setTemplatesTableFilters } from "../../redux/message_templates_slice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function MessageTemplates() {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.layoutSlice);
    const { templateDataList, templateTableFilters } = useSelector((state) => state.templatesSlice);

    const [totalPages, setTotalPages] = useState(1);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [deleteTemplateValue, setDeleteTemplateValue] = useState(null)

    useEffect(() => {
        getTemplates();
    }, [templateTableFilters])

    const getTemplates = async () => {
        dispatch(setIsLoading(true))
        let response = await postAxiosWithToken({
            url: templatesListEndPoint,
            body: templateTableFilters
        })

        if (response != null) {
            dispatch(setTemplatesDataList(response.data.list))
            setTotalPages(response.data.total_pages)
            dispatch(setIsLoading(false))
        }
    }

    const entriesHandler = (value) => {
        dispatch(setTemplatesTableFilters({
            items_per_page: value,
            search: templateTableFilters.search,
            page: templateTableFilters.page
        }))
    }

    const deleteTemplate = async (id) => {
        setIsDeleteLoading(true);
        let response = await deleteAxiosWithToken({
            url: `${templatesEndPoint}?id=${id}`
        })

        if (response != null) {
            setIsDeleteOpen(false)
            setDeleteTemplateValue(null)
            setIsDeleteLoading(false);

            toast.success(response.message)
            getTemplates()
        }
    }

    return (
        <>
            <div>
                <div className='my-[10px]'>
                    <input type='text' name='search' id='search' onChange={(e) => {
                        dispatch(setTemplatesTableFilters({
                            items_per_page: templateTableFilters.items_per_page,
                            search: e.target.value,
                            page: templateTableFilters.page
                        }))
                    }} className='text-sm bg-input-gray rounded focus:outline-primary w-[300px] px-[15px] py-[10px]' placeholder="search" />
                </div>
            </div>
            <div className="w-full">
                <table className="w-full">
                    <thead className="bg-light-gray text-white">
                        <tr>
                            <th className="p-[10px] text-left">S No</th>
                            <th className="p-[10px] text-left">Template Name</th>
                            <th className="p-[10px] text-left">Excel</th>
                            <th className="p-[10px] text-left">Multi Sheet</th>
                            <th className="p-[10px] text-left">Excel Image</th>
                            <th className="p-[10px] text-left">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isLoading ? <tr className=""><td colSpan={6}><div className="w-fit mx-auto py-5"><Loading /></div></td></tr> : templateDataList.map((item, index) => (
                                <tr className={`${index % 2 != 0 ? "bg-input-gray" : ""} border-b`}>
                                    <td className="p-[10px]">{index + 1}</td>
                                    <td className="p-[10px]">{item.template_name}</td>
                                    <td className="p-[10px]">{item.has_excel ? 'Yes' : 'No'}</td>
                                    <td className="p-[10px]">{item.has_multi_sheet ? 'Yes' : 'No'}</td>
                                    <td className="p-[10px]">{item.excel_image ? 'Yes' : 'No'}</td>
                                    <td className="p-[10px]">
                                    <div className="flex items-center gap-3">
                                            <button onClick={(e) => {e.preventDefault(); navigate('/message-templates/'+item.id) }}>
                                                <span className="i-gravity-ui-pencil w-6 h-6"></span>
                                            </button>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                setIsDeleteOpen(true);
                                                setDeleteTemplateValue(item)
                                            }}>
                                                <span className="i-fluent-delete-12-filled w-6 h-6"></span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <div className="my-[5px]">
                <div className='flex justify-between min-[500px]:flex gap-5'>
                    <div className='max-[499px]:mb-[15px] flex flex-wrap gap-3 items-center'>

                        <p>Entries</p>
                        {/* <br/> */}
                        <select value={templateTableFilters.items_per_page} onChange={(e) => entriesHandler(e.target.value)} className='border rounded-md w-[50px] font-400 text-[15px] p-[5px] outline-primary bg-white'>
                            {[10, 20, 30, 40, 50].map((limit) => (
                                <option key={limit} value={limit}>
                                    {limit}
                                </option>
                            ))}
                        </select>

                    </div>
                    <div className="w-[50%] flex justify-end gap-[5px]">
                        {/* <button onClick={(e) => { e.preventDefault(); setCurrentPage(1); }} className='cursor-pointer border text-black min-w-[35px] h-[35px] rounded flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path fill="currentColor" fillRule="evenodd" d="M15.707 15.707a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414l5-5a1 1 0 1 1 1.414 1.414L11.414 10l4.293 4.293a1 1 0 0 1 0 1.414m-6 0a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414l5-5a1 1 0 0 1 1.414 1.414L5.414 10l4.293 4.293a1 1 0 0 1 0 1.414" clipRule="evenodd" />
          </svg>
        </button> */}
                        <ResponsivePagination
                            total={totalPages}
                            current={templateTableFilters.page}
                            onPageChange={page => {
                                dispatch(setTemplatesTableFilters({
                                    items_per_page: templateTableFilters.items_per_page,
                                    search: templateTableFilters.search,
                                    page: page
                                }))
                            }}
                            className='flex gap-[5px]'
                            activeItemClassName='!text-white !bg-primary'
                            pageLinkClassName='w-full text-center'
                            pageItemClassName='cursor-pointer bg-[#F1F1F1] text-black w-[35px] h-[35px] rounded-[8px] flex items-center justify-center '
                            disabledItemClassName='border-none text-black'
                            nextClassName='text-black bg-white'
                            previousClassName='text-black bg-white'
                            previousLabel='<'
                            nextLabel='>'
                        />
                        {/* <button onClick={(e) => { e.preventDefault(); setCurrentPage(totalPages); }} className='cursor-pointer border text-black min-w-[35px] h-[35px] rounded flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
              <path d="M10.293 15.707a1 1 0 0 1 0-1.414L14.586 10l-4.293-4.293a1 1 0 1 1 1.414-1.414l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0" />
              <path d="M4.293 15.707a1 1 0 0 1 0-1.414L8.586 10L4.293 5.707a1 1 0 0 1 1.414-1.414l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0" />
            </g>
          </svg>
        </button> */}
                    </div>
                </div>
            </div>

            <Dialog open={isDeleteOpen} onClose={() => setIsDeleteOpen(false)} className="relative z-50">
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel className="max-w-lg space-y-4 border bg-white p-12">
                        <DialogTitle className="font-bold">Delete Template</DialogTitle>
                        <Description>This will permanently delete the template</Description>
                        <p>Are you sure you want to delete the template?</p>
                        <div className="flex gap-4">
                            <button className="bg-light-gray px-[10px] text-white" onClick={() => {
                                setIsDeleteOpen(false)
                                setDeleteTemplateValue(null)
                            }}>Cancel</button>
                            <button className="bg-primary px-[10px] text-white" onClick={() => {
                                deleteTemplate(deleteTemplateValue.id)
                            }}>{isDeleteLoading ? "deleting..." : "Delete"}</button>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>
        </>
    );
}
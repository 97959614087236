
import { useFormik } from "formik";
import ContactsList from "./contact_list";
import GroupsList from "./groups_list";
import * as Yup from 'yup';
import { useState } from "react";
import { postAxiosWithToken } from "../../service/axios_service";
import { chapterEndPoint } from "../../service/api_endpoints";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

export default function CreateChapter() {

    const navigate = useNavigate();

    const [selectedGroupList, setSelectedGroupList] = useState([]);
    const [selectedContactList, setSelectedContactList] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            chapter_name: '',
        },
        validationSchema: Yup.object({
            chapter_name: Yup.string()
                .required('Chapter name is required'),
        }),
        onSubmit: async (values) => {
            setSubmitLoading(true);
            
            var tempGroupList = []

            for (var i of selectedGroupList) {
                tempGroupList.push(i.id)
            }

            var tempContactList = []

            for (var i of selectedContactList) {
                tempContactList.push(i.id)
            }

            if (tempGroupList.length > 0 || tempContactList.length > 0) {
                
                
                let body = {
                    "chapter_name": values.chapter_name,
                    "group_details": tempGroupList,
                    "contact_details": tempContactList
                }
                
                var response = await postAxiosWithToken({
                    url: chapterEndPoint,
                    body: body
                })
                setSubmitLoading(false)
                if (response != null) {
                    navigate("/fav-groups")
                    toast.success(response.message)
                }
            } else {
                toast.info('Atleast 1 group or 1 contact should be added!')
            }
        },
    });

    return (
        <div className="relative">
            <form onSubmit={formik.handleSubmit}>
                <div className='my-[10px]'>
                    <p>Fav Group Name</p>
                    <input type='text' name='chapter_name' id='chapter_name' placeholder="fav group name" className='text-sm bg-input-gray rounded focus:outline-primary w-full px-[15px] py-[10px]' onChange={formik.handleChange} value={formik.values.chapter_name} />
                    {formik.errors.chapter_name && <p className='text-red-500 text-xs'>{formik.errors.chapter_name}</p>}
                </div>

                <div className="hidden">
                    <button type='submit' id="createchapter" className='w-[300px] bg-primary px-[15px] py-[10px] text-white rounded'></button>
                </div>
            </form>

            <p className="mt-[25px] font-bold text-xl">Groups List</p>
            <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 sm:grid-cols-1  gap-3">
                <div className="w-full overflow-scroll">
                    <GroupsList selectedGroupList={selectedGroupList} setSelectedGroupList={setSelectedGroupList} />
                </div>

                <div className="w-full overflow-scroll mt-[60px]">
                    <table className="w-full">
                        <thead className="bg-light-gray text-white">
                            <tr>
                                <th className="p-[10px] text-left">S No</th>
                                <th className="p-[10px] text-left">Group Id</th>
                                <th className="p-[10px] text-left">Group Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedGroupList.map((item, index) => (
                                    <tr onClick={() => {
                                        let data = [...selectedGroupList]
                                        data.splice(index, 1)
                                        setSelectedGroupList(data)
                                    }} className={`${index % 2 != 0 ? "bg-input-gray" : ""} border-b`}>
                                        <td className="p-[10px]">{index + 1}</td>
                                        <td className="p-[10px]">{item.group_id}</td>
                                        <td className="p-[10px] ">{item.group_name}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <p className="mt-[25px] font-bold text-xl">Contacts List</p>
            <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 gap-3">
                <div className="w-full overflow-scroll">
                    <ContactsList selectedContactList={selectedContactList} setSelectedContactList={setSelectedContactList} />
                </div>

                <div className="w-full overflow-scroll mt-[60px] ">
                    <table className="w-full">
                        <thead className="bg-light-gray text-white">
                            <tr>
                                <th className="p-[10px] text-left">S No</th>
                                <th className="p-[10px] text-left">Contact Id</th>
                                <th className="p-[10px] text-left">Contact Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedContactList.map((item, index) => (
                                    <tr onClick={() => {
                                        let data = [...selectedContactList]
                                        data.splice(index, 1)
                                        setSelectedContactList(data)
                                    }} className={`${index % 2 != 0 ? "bg-input-gray" : ""} border-b`}>
                                        <td className="p-[10px]">{index + 1}</td>
                                        <td className="p-[10px]">{item.contact_id}</td>
                                        <td className="p-[10px] ">{item.contact_name}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <button type="button" onClick={() => {
                document.getElementById('createchapter').click()
            }} className='w-full my-[50px] bg-primary px-[15px] py-[10px] text-white rounded'>{submitLoading ? "Loading..." : "Create Fav Group" }</button>
        </div>
    )
}
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { getAxiosWithToken, postAxiosWithToken, putAxiosWithToken } from '../../service/axios_service';
import { templatesEndPoint } from '../../service/api_endpoints';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../session/Loading';
import { setIsLoading } from '../../redux/layout_slice';

export default function CreateTemplate() {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.layoutSlice);

    const { id } = useParams();

    const [submitLoading, setSubmitLoading] = useState(false);

    const [innerText, setInnerText] = useState("");

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote',], //'code-block'
        // ['link', 'image', 'video', 'formula'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        // [{ 'font': [] }],
        [{ 'align': [] }],

        // ['clean']                                         // remove formatting button
    ];

    const formik = useFormik({
        initialValues: {
            template_name: '',
            has_excel: 'no',
            has_link: 'no',
            has_multi_sheet: 'no',
            excel_image: 'no',
            is_sortable: 'no',
            leave_row: 'no',
        },
        validationSchema: Yup.object({
            template_name: Yup.string()
                .required('Template name is required'),
            has_excel: Yup.string(),
            has_link: Yup.string(),
            has_multi_sheet: Yup.string(),
            excel_image: Yup.string(),
            is_sortable: Yup.string(),
            leave_row: Yup.string()
        }),
        onSubmit: async (values) => {
            setSubmitLoading(true);

            if (id?.length > 0) {
                let body = {
                    id: id,
                    template_name: values.template_name,
                    content: document.getElementById('preview').innerText,
                    content_html: innerText,
                    has_excel: values.has_excel == 'yes' ? true : false,
                    has_multi_sheet: values.has_multi_sheet == "yes" ? true : false,
                    excel_image: values.excel_image == "yes" ? true : false,
                    is_sortable: values.is_sortable == "yes" ? true : false,
                    leave_row: values.leave_row == "yes" ? true : false,
                    has_link: values.has_link == "yes" ? true : false
                }
                let response = await putAxiosWithToken({
                    url: templatesEndPoint,
                    body: body
                })

                if (response != null) {
                    navigate("/message-templates")
                    toast.success(response.message)
                }
            } else {
                let body = {
                    template_name: values.template_name,
                    content: document.getElementById('preview').innerText,
                    content_html: innerText,
                    has_excel: values.has_excel == 'yes' ? true : false,
                    has_multi_sheet: values.has_multi_sheet == "yes" ? true : false,
                    excel_image: values.excel_image == "yes" ? true : false,
                    is_sortable: values.is_sortable == "yes" ? true : false,
                    leave_row: values.leave_row == "yes" ? true : false,
                    has_link: values.has_link == "yes" ? true : false
                }
                let response = await postAxiosWithToken({
                    url: templatesEndPoint,
                    body: body
                })

                if (response != null) {
                    navigate("/message-templates")
                    toast.success(response.message)
                }
            }

            setSubmitLoading(false);
        },
    });

    useEffect(() => {
        if (id != null && id != undefined) {
            getTemplateDetails()
        } else {
            dispatch(setIsLoading(false))
        }
    }, [])

    const getTemplateDetails = async () => {
        let response = await getAxiosWithToken({
            url: `${templatesEndPoint}?id=${id}`
        })

        if (response != null) {
            formik.setFieldValue('template_name', response.data.template_name)
            formik.setFieldValue('has_excel', response.data.has_excel ? 'yes' : 'no')
            formik.setFieldValue('has_multi_sheet', response.data.has_multi_sheet ? 'yes' : 'no')
            formik.setFieldValue('excel_image', response.data.excel_image ? 'yes' : 'no')
            formik.setFieldValue('is_sortable', response.data.is_sortable ? 'yes' : 'no')
            formik.setFieldValue('leave_row', response.data.leave_row ? 'yes' : 'no')
            formik.setFieldValue('has_link', response.data.has_link ? 'yes' : 'no')
            setInnerText(response.data.content_html)
            dispatch(setIsLoading(false))
        }
    }

    return <>
        {
            isLoading ? <div className='h-[calc(100vh-200px)] w-full flex items-center justify-center'><Loading /></div> :

                <form onSubmit={formik.handleSubmit}>
                    <div className='my-[10px]'>
                        <p>Template Name</p>
                        <input type='text' name='template_name' id='template_name' placeholder="template name" className='text-sm bg-input-gray rounded focus:outline-primary w-full px-[15px] py-[10px]' onChange={formik.handleChange} value={formik.values.template_name} />
                        {formik.errors.template_name && <p className='text-red-500 text-xs'>{formik.errors.template_name}</p>}
                    </div>
                    <p className='my-[10px]'>Template Content</p>
                    <div className='w-full'>
                        <ReactQuill theme='snow' value={innerText} onChange={(value) => { setInnerText(value) }} modules={{
                            toolbar: toolbarOptions
                        }} />
                    </div>

                    {
                        innerText?.length > 0 && <div>
                            <p className='my-[10px]'>Template Preview</p>
                            <div id="preview" dangerouslySetInnerHTML={{ __html: innerText }} className='w-full break-all border rounded shadow p-[10px]'></div>
                        </div>
                    }

                    <div className='my-[10px]'>
                        <p>Has Excel</p>
                        <select name='has_excel' id='has_excel' value={formik.values.has_excel} onChange={formik.handleChange} className='text-sm bg-input-gray rounded focus:outline-primary w-full px-[15px] py-[10px]'>
                            <option value={"yes"}>Yes</option>
                            <option value={"no"}>No</option>
                        </select>
                    </div>

                    {
                        formik.values.has_excel == "yes" && <div className='my-[10px]'>
                            <p>Has Multi Sheet</p>
                            <select name='has_multi_sheet' id='has_multi_sheet' value={formik.values.has_multi_sheet} onChange={formik.handleChange} className='text-sm bg-input-gray rounded focus:outline-primary w-full px-[15px] py-[10px]'>
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                            </select>
                        </div>}

                    {
                        formik.values.has_excel == "yes" && <div className='my-[10px]'>
                            <p>Send Excel as Image</p>
                            <select name='excel_image' id='excel_image' value={formik.values.excel_image} onChange={formik.handleChange} className='text-sm bg-input-gray rounded focus:outline-primary w-full px-[15px] py-[10px]'>
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                            </select>
                        </div>
                    }

                    {
                        formik.values.has_excel == "yes" && <div className='my-[10px]'>
                            <p>Sort The Excel</p>
                            <select name='is_sortable' id='is_sortable' value={formik.values.is_sortable} onChange={formik.handleChange} className='text-sm bg-input-gray rounded focus:outline-primary w-full px-[15px] py-[10px]'>
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                            </select>
                        </div>
                    }

                    {
                        formik.values.has_excel == "yes" && <div className='my-[10px]'>
                            <p>Leave Any Rows</p>
                            <select name='leave_row' id='leave_row' value={formik.values.leave_row} onChange={formik.handleChange} className='text-sm bg-input-gray rounded focus:outline-primary w-full px-[15px] py-[10px]'>
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                            </select>
                        </div>
                    }

                    {
                        formik.values.has_excel == "yes" && <div className='my-[10px]'>
                            <p>Has Link</p>
                            <select name='has_link' id='has_link' value={formik.values.has_link} onChange={formik.handleChange} className='text-sm bg-input-gray rounded focus:outline-primary w-full px-[15px] py-[10px]'>
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                            </select>
                        </div>
                    }

                    <button type="submit" className='w-full my-[50px] bg-primary px-[15px] py-[10px] text-white rounded'>{submitLoading ? "Loading..." : id?.length > 0 ? "Update Template" : "Create Template"}</button>
                </form>}
    </>;
}
import { useEffect, useState } from "react";
import { getAxiosWithToken } from "../../service/axios_service";
import { chapterEndPoint } from "../../service/api_endpoints";
import { useParams } from "react-router-dom";
import { setIsLoading } from "../../redux/layout_slice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../session/Loading";

export default function ViewChapter() {

    const dispatch = useDispatch();

    const { id } = useParams();

    const { isLoading } = useSelector((state) => state.layoutSlice);

    const [chapterDetails, setChapterDetails] = useState({});
    const [groupsList, setGroupList] = useState([]);
    const [contactsList, setContactList] = useState([]);

    useEffect(() => {
        getChapterDetails()
    }, [])

    const getChapterDetails = async () => {
        let response = await getAxiosWithToken({
            url: `${chapterEndPoint}?id=${id}`
        })

        if (response != null) {
            setChapterDetails(response.data)
            setGroupList(response.data?.group_details)
            setContactList(response.data?.contact_details)
            dispatch(setIsLoading(false))
        }
    }
    return (
        isLoading ? <div className='h-[calc(100vh-200px)] w-full flex items-center justify-center'><Loading /></div> :
        <div>
            <p className="text-2xl mb-[25px] font-bold">: {chapterDetails.chapter_name}</p>
            <div className="flex gap-1">
                <div className="w-1/2 h-[calc(100vh-200px)] overflow-scroll">
                    <table className="w-full">
                        <thead className="bg-light-gray text-white">
                            <tr>
                                <th className="p-[10px] text-left">S No</th>
                                <th className="p-[10px] text-left">Fav Group Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                groupsList?.map((item, index) => (
                                    <tr className={`${index % 2 != 0 ? "bg-input-gray" : ""} border-b`}>
                                        <td className="p-[10px]">{index + 1}</td>
                                        <td className="p-[10px] ">{item.group_details__group_name}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="w-1/2 h-[calc(100vh-200px)] overflow-scroll">
                    <table className="w-full">
                        <thead className="bg-light-gray text-white">
                            <tr>
                                <th className="p-[10px] text-left">S No</th>
                                <th className="p-[10px] text-left">Contact Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                contactsList?.map((item, index) => (
                                    <tr className={`${index % 2 != 0 ? "bg-input-gray" : ""} border-b`}>
                                        <td className="p-[10px]">{index + 1}</td>
                                        <td className="p-[10px] ">{item.contact_details__contact_name}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
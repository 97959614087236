import React, { useEffect, useState } from 'react'
import Breadcrumbs from './breadcrumbs'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading, setIsMenuOpen, setIsWhatsappLoggedIn } from '../../redux/layout_slice'
import { getAxiosWithToken, postAxiosWithToken } from '../../service/axios_service'
import { groupListEndPoint, profileInfoEndPoint, syncDataEndPoint } from '../../service/api_endpoints'
import Loading from '../../session/Loading'

export default function Header() {

  const navigate = useNavigate();

  const { isMenuOpen, isWhatsappLoggedIn } = useSelector((state) => state.layoutSlice);

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const [userProfileIcon, setUserProfileIcon] = useState("")
  const [syncDataLoading, setSyncDataLoading] = useState(false)
  const [userProfileName, setUserProfileName] = useState("")
  const [profileLoading, setProfileLoading] = useState(true);

  useEffect(() => {
    getUserProfile();
  }, [pathname])

  const getUserProfile = async () => {
    let response = await getAxiosWithToken({
      url: profileInfoEndPoint
    })

    setProfileLoading(false);
    if (response != null) {
      if (response.message.toLowerCase() == "Data retrieved sucessfully".toLowerCase()) {
        dispatch(setIsWhatsappLoggedIn(true))
        setUserProfileIcon(response.data.icon)
        setUserProfileName(response.data.name)
      } else {
        dispatch(setIsWhatsappLoggedIn(false))
      }
    } else {
      dispatch(setIsWhatsappLoggedIn(false))
    }
  }

  const syncData = async () => {
    setSyncDataLoading(true)
    let response = await getAxiosWithToken({
      url: syncDataEndPoint
    })
    setSyncDataLoading(false)
    if (response != null) {
      window.location.reload()
    }
  }

  return (
    <div className='shadow px-[10px] md:px-[25px] py-[10px] md:py-[15px] h-[70px] bg-header-bg flex items-center justify-between gap-5'>
      <div className='flex gap-2'>
        <div onClick={() => {
          dispatch(setIsMenuOpen(!isMenuOpen))
        }} className='sm:hidden'>
          <span className='i-lucide-menu w-7 h-7'></span>
        </div>
        <div className='max-[400px]:hidden'>
          <p className='text-sm md:text-xl font-medium mb-2 uppercase'>{pathname == "/" ? 'Home' : pathname.split('/')[pathname.split('/').length - 1]}</p>
          {pathname != "/" && <Breadcrumbs />}
        </div>
      </div>
      <div className='flex items-center gap-2 md:gap-7'>
        <div onClick={() => navigate('/create-template')} className=' '>
          <span className='i-marketeq-create-note max-[400px]:w-4 max-[400px]:h-4 w-8 h-8 text-primary stroke-primary'></span>
        </div>

        <div onClick={() => navigate('/create-fav-group')} className='  '>
          <span className='i-fluent-note-add-16-regular w-8 h-8 text-primary'></span>
        </div>

        {
          syncDataLoading ? <div ><Loading /></div> : <div onClick={() => syncData()} className=''><span className='i-uil-sync w-8 h-8 text-primary'></span></div>
        }

        {!profileLoading && <div className='flex gap-3 items-center'>

          <div className='w-[45px] h-[45px] rounded-full overflow-hidden'>
            <img src={userProfileIcon} width={"100%"} />
          </div>
          <div className='hidden sm:block'>
            <p className='text-sm '>{userProfileName}</p>
            <p className='text-sm text-light-gray'>{isWhatsappLoggedIn ? 'Logged In' : "Logged Out"}</p>
          </div>
        </div>}
      </div>
    </div>
  )
}

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    groupDataList: [],
    groupTableFilters: {
        search: "",
        page: 1,
        items_per_page: 10
    }
}

export const groupsSlice = createSlice({
    name: 'groupsSlice',
    initialState: initialState,
    reducers: {
        setGroupDataList: (state, action) => {
            state.groupDataList = action.payload
        },
        setGroupTableFilters: (state, action) => {
            state.groupTableFilters = action.payload
        }
    }
})

export const { setGroupDataList, setGroupTableFilters } = groupsSlice.actions

export default groupsSlice.reducer
import { configureStore } from '@reduxjs/toolkit';
import layoutSliceReducer from './layout_slice';
import groupsSliceReducer from './groups_slice';
import contactsSliceReducer from './contacts_slice';
import templatesSliceReducer from './message_templates_slice';

export const store = configureStore({
  reducer: {
    layoutSlice: layoutSliceReducer,
    groupsSlice: groupsSliceReducer,
    contactsSlice: contactsSliceReducer,
    templatesSlice: templatesSliceReducer
  },
})
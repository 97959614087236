import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isMenuOpen: false,
    isWhatsappLoggedIn: false,
    isLoading: true
}

export const layoutSlice = createSlice({
    name: 'layoutSlice',
    initialState: initialState,
    reducers: {
        setIsMenuOpen: (state, action) => {
            state.isMenuOpen = action.payload
        },
        setIsWhatsappLoggedIn: (state, action) => {
            state.isWhatsappLoggedIn = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        }
    }
})

export const { setIsMenuOpen, setIsWhatsappLoggedIn, setIsLoading } = layoutSlice.actions

export default layoutSlice.reducer
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    contactDataList: [],
    contactTableFilters: {
        search: "",
        page: 1,
        items_per_page: 10
    }
}

export const contactsSlice = createSlice({
    name: 'contactsSlice',
    initialState: initialState,
    reducers: {
        setContactDataList: (state, action) => {
            state.contactDataList = action.payload
        },
        setContactTableFilters: (state, action) => {
            state.contactTableFilters = action.payload
        }
    }
})

export const { setContactDataList, setContactTableFilters } = contactsSlice.actions

export default contactsSlice.reducer
import { useEffect, useState } from "react"
import { chapterListEndPoint } from "../../service/api_endpoints";
import Loading from "../../session/Loading";
import ResponsivePagination from "react-responsive-pagination";
import { getAxiosWithToken, postAxiosWithToken } from "../../service/axios_service";

export default function ChapterList({ selectedChapterList, setSelectedChapterList }) {

    const [chapterList, setChapterList] = useState([]);

    const [chapterLoading, setChatperLoading] = useState([]);
    const [totalChapterPages, setTotalChapterPages] = useState(1);
    const [chapterTableFilters, setChapterTableFilters] = useState({
        search: "",
        page: 1,
        items_per_page: 10
    })

    useEffect(() => {
        getChapterList();
    }, [chapterTableFilters])

    const getChapterList = async () => {
        let response = await getAxiosWithToken({
            url: `${chapterListEndPoint}?search=${chapterTableFilters.search}&items_per_page=${chapterTableFilters.items_per_page}&page=${chapterTableFilters.page}`,
        })

        if (response != null) {
            setChapterList(response.data.list)
            setTotalChapterPages(response.data.total_pages)
            setChatperLoading(false)
        }
    }

    const groupEntriesHandler = (value) => {
        setChapterTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value
        }))
    }

    return (
        <>
            <div className='my-[10px]'>
                <input type='text' name='search' id='search' onChange={(e) => {
                    setChapterTableFilters((prevState) => ({
                        ...prevState,
                        search: e.target.value
                    }))
                }} className='text-sm bg-input-gray rounded focus:outline-primary w-full  px-[15px] py-[10px]' placeholder="search" />
            </div>
            <div className="w-full ">
                <table className="w-full">
                    <thead className="bg-light-gray text-white">
                        <tr>
                            <th className="p-[10px] text-left">S No</th>
                            <th className="p-[10px] text-left">Fav Group Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            chapterLoading ? <tr className=""><td colSpan={3}><div className="w-fit mx-auto py-5"><Loading /></div></td></tr> : chapterList.map((item, index) => (
                                <tr onClick={() => {
                                    let data = [...selectedChapterList];

                                    let filterData = data.filter((value) => value.id.toString().toLowerCase() == item.id.toString().toLowerCase())

                                    if (filterData.length == 0) {
                                        data.push(item)
                                    }

                                    setSelectedChapterList(data)
                                }} className={`${index % 2 != 0 ? "bg-input-gray" : ""} border-b`}>
                                    <td className="p-[10px]">{index + 1}</td>
                                    <td className="p-[10px] ">{item.chapter_name}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <div className="my-[5px]">
                <div className='flex justify-between min-[500px]:flex gap-5'>
                    <div className='max-[499px]:mb-[15px] flex flex-wrap gap-3 items-center'>

                        <p>Entries</p>
                        {/* <br/> */}
                        <select value={chapterTableFilters.items_per_page} onChange={(e) => groupEntriesHandler(e.target.value)} className='border rounded-md w-[50px] font-400 text-[15px] p-[5px] outline-primary bg-white'>
                            {[10, 20, 30, 40, 50].map((limit) => (
                                <option key={limit} value={limit}>
                                    {limit}
                                </option>
                            ))}
                        </select>

                    </div>
                    <div className="w-[50%] flex justify-end gap-[5px]">
                        {/* <button onClick={(e) => { e.preventDefault(); setCurrentPage(1); }} className='cursor-pointer border text-black min-w-[35px] h-[35px] rounded flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path fill="currentColor" fillRule="evenodd" d="M15.707 15.707a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414l5-5a1 1 0 1 1 1.414 1.414L11.414 10l4.293 4.293a1 1 0 0 1 0 1.414m-6 0a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414l5-5a1 1 0 0 1 1.414 1.414L5.414 10l4.293 4.293a1 1 0 0 1 0 1.414" clipRule="evenodd" />
          </svg>
        </button> */}
                        <ResponsivePagination
                            total={totalChapterPages}
                            current={chapterTableFilters.page}
                            onPageChange={page => {
                                setChapterTableFilters((prevState) => ({
                                    ...prevState,
                                    page: page
                                }))
                            }}
                            className='flex gap-[5px]'
                            activeItemClassName='!text-white !bg-primary'
                            pageLinkClassName='w-full text-center'
                            pageItemClassName='cursor-pointer bg-[#F1F1F1] text-black w-[35px] h-[35px] rounded-[8px] flex items-center justify-center '
                            disabledItemClassName='border-none text-black'
                            nextClassName='text-black bg-white'
                            previousClassName='text-black bg-white'
                            previousLabel='<'
                            nextLabel='>'
                        />
                        {/* <button onClick={(e) => { e.preventDefault(); setCurrentPage(totalPages); }} className='cursor-pointer border text-black min-w-[35px] h-[35px] rounded flex items-center justify-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
              <path d="M10.293 15.707a1 1 0 0 1 0-1.414L14.586 10l-4.293-4.293a1 1 0 1 1 1.414-1.414l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0" />
              <path d="M4.293 15.707a1 1 0 0 1 0-1.414L8.586 10L4.293 5.707a1 1 0 0 1 1.414-1.414l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0" />
            </g>
          </svg>
        </button> */}
                    </div>
                </div>
            </div>
        </>
    )
}
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    templateDataList: [],
    templateTableFilters: {
        search: "",
        page: 1,
        items_per_page: 10
    }
}

export const templatesSlice = createSlice({
    name: 'templatesSlice',
    initialState: initialState,
    reducers: {
        setTemplatesDataList: (state, action) => {
            state.templateDataList = action.payload
        },
        setTemplatesTableFilters: (state, action) => {
            state.templateTableFilters = action.payload
        }
    }
})

export const { setTemplatesDataList, setTemplatesTableFilters } = templatesSlice.actions

export default templatesSlice.reducer